import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales/quotation',
        component: () => import('@/layout/QuotationLayout.vue'),
        redirect: '/sales/quotation/menunggu-approval',
        children: [
            {
                path: 'menunggu-approval',
                name: 'menunggu approval',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'sedang-berjalan',
                name: 'sedang berjalan',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'win',
                name: 'win',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'lost',
                name: 'lost',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'ditolak',
                name: 'ditolak',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'dibatalkan',
                name: 'dibatalkan',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'expired',
                name: 'expired',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'purchase-discount-history',
                name: 'purchase-discount-history',
                component: () => import('@/views/QuotationView.vue'),
            },
            {
                path: 'detail/:id',
                name: 'detail',
                component: () => import('@/views/QuotationDetailView.vue'),
            },
            {
                path: 'buat',
                name: 'buat',
                component: () => import('@/views/QuotationCreateView.vue'),
            },
            {
                path: 'edit/:id',
                name: 'edit',
                component: () => import('@/views/QuotationEditView.vue'),
            },
            {
                path: 'revisi-masa-berlaku/:id',
                name: 'revisi',
                component: () => import('@/views/QuotationRevisionView.vue'),
            },
            {
                path: 'pengajuan-ulang/:id',
                name: 'pengajuan',
                component: () => import('@/views/QuotationResubmissionView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
